<template>
  <section>
    <div class="container-page big">
      <h2 class="account-header">Личный кабинет сопредседателя мпк</h2>
      <!-- select -->
      <div class="account-country">
        <span class="fs24 fw600 mr-10">Страна</span>
        <div class="account-custom-select w-300">
          <Multiselect
            v-model="countryId"
            valueProp="id"
            track-by="text"
            label="text"
            placeholder="Выберите страну"
            :searchable="true"
            :options="getCountryList"
            noResultsText="Ничего не найдено."
            noOptionsText="Список пуст."
            @select="onCountryFilter"
          >
          </Multiselect>
        </div>
      </div>
      <!-- select -->

      <!-- Якоря -->
      <ul class="anchors-list mb-54">
        <template v-for="item in menuAnchorsArr" :key="item.key">
          <li v-if="item.visible" class="anchors-list-item" @click="scrollTo(item.anchor)">
            {{ item.title }}
          </li>
        </template>
      </ul>
      <!-- Якоря -->

      <!-- Ключевые результаты  -->
      <div v-if="currentSummary" class="key-results mb-54">
        <div class="key-results__header">
          <div class="key-results__period" ref="keyResults">
            <h3 class="key-results__key-header">Ключевые результаты за</h3>
            <div class="account-custom-select w-220">
              <Multiselect
                v-model="currentYear"
                valueProp="id"
                track-by="text"
                label="text"
                placeholder="Период"
                :options="yearList"
                noResultsText="Ничего не найдено."
                noOptionsText="Список пуст."
                @select="fetchHotIndicator"
              >
              </Multiselect>
            </div>
          </div>
          <RedesignedSwitchComponent :checked="isProductMode" @switchHandler="switchHand($event)" />
          <CustomLink :to="{ name: 'countryAnalytics', query: { countryId } }">Подробнее</CustomLink>

          <p class="fs16">Торгово-экономическое сотрудничество с Россией</p>
        </div>

        <div class="key-results">
          <div class="mb-82" v-if="currentSummaryLoading">
            <p>Загрузка данных...</p>
          </div>
          <div class="indicators" v-if="!currentSummaryLoading">
            <!-- Товарооборот -->
            <AccountCochairIndicator
              name="Товарооборот"
              :current-summary="currentSummary"
              :indicator-value="indicatorValue.TOTAL_VALUE"
              :previous-percent="indicatorPreviousPercent.TOTAL_VALUE"
              :divider="divider"
              :diffPlace="diffPlace.TRADE_TURNOVER_PLACE"
              :indicator-place="indicatorPlaceInMarket.TRADE_TURNOVER"
            />

            <!-- Импорт -->
            <AccountCochairIndicator
              name="Импорт"
              :current-summary="currentSummary"
              :indicator-value="indicatorValue.IMPORT_VALUE"
              :previous-percent="indicatorPreviousPercent.IMPORT_VALUE"
              :divider="divider"
              :diffPlace="diffPlace.IMPORT_PLACE"
              :indicator-place="indicatorPlaceInMarket.IMPORT"
            />

            <!-- Экспорт -->
            <AccountCochairIndicator
              name="Экспорт"
              :current-summary="currentSummary"
              :indicator-value="indicatorValue.EXPORT_VALUE"
              :previous-percent="indicatorPreviousPercent.EXPORT_VALUE"
              :divider="divider"
              :diffPlace="diffPlace.EXPORT_PLACE"
              :indicator-place="indicatorPlaceInMarket.EXPORT"
            />

            <!-- НЭЭ -->
            <AccountCochairIndicator
              v-if="isProductMode === true"
              name="НЭЭ"
              :current-summary="currentSummary"
              :indicator-value="indicatorValue.EXPORT_NON_ENERGY_VALUE"
              :previous-percent="indicatorPreviousPercent.EXPORT_NON_ENERGY_VALUE"
              :divider="divider"
              :diffPlace="diffPlace.EXPORT_NON_ENERGY_PLACE"
              :indicator-place="indicatorPlaceInMarket.EXPORT_NON_ENERGY_VALUE"
            />

            <!-- Сальдо -->
            <AccountCochairIndicator
              name="Сальдо"
              :current-summary="currentSummary"
              :indicator-value="indicatorValue.BALANCE"
              :previous-percent="indicatorPreviousPercent.BALANCE"
              :divider="divider"
              :diffPlace="diffPlace.BALANCE_PLACE"
              :indicator-place="indicatorPlaceInMarket.BALANCE"
            />
          </div>
        </div>
      </div>

      <!-- Рейтинг эффективности МПК -->
      <div v-if="efficiencyRatingPlace != null" class="efficiency mb-54">
        <h3 class="efficiency__key-header" ref="efficiency">Рейтинг эффективности МПК {{ efficiencyRating }}</h3>
        <CustomLink :to="{ name: 'efficiencyrating', query: { countryId } }">Подробнее</CustomLink>
      </div>

      <div class="mb-54">
        <!-- Сопредседатели МПК -->
        <div v-if="mpk" class="sopred-mpk">
          <div class="sopred-mpk__wrapper-header">
            <div class="sopred-mpk__header">
              <h3 class="sopred-mpk__title" ref="structureMpk">Структура МПК</h3>
              <h4 class="sopred-mpk__sub-title">Групп {{ workOrgansArr.length }}</h4>
              <h4 class="sopred-mpk__sub-title">Комиссий {{ mpkCommissionByCountry.length }}</h4>
            </div>
            <div
              class="hide-btn-with-block-arrow"
              :class="{ active: isShowSopredMpk }"
              @click="isShowSopredMpk = !isShowSopredMpk"
            >
              <p>
                {{ isShowSopredMpk ? 'Cвернуть' : 'Развернуть' }}
              </p>
              <span></span>
            </div>
          </div>
          <div class="sopred-mpk__container" v-if="sopredArr?.length > 0 && isShowSopredMpk">
            <div class="sopred-mpk__block">
              <div class="sopred-mpk__list">
                <h4 class="sopred-mpk__list-header">Сопредседатели МПК</h4>
                <div class="sopred-mpk__list-item" v-for="item in sopredArr" :key="item.key">
                  <div class="item-img-block">
                    <!-- fake templates avatar remove when connect to back -->
                    <template v-if="item.picture">
                      <img class="item-img-block-avatar" :src="item.picture" alt="avatar" />
                    </template>
                    <template v-else>
                      <div class="item-img-block-avatar"></div>
                    </template>
                    <!-- fake templates avatar remove when connect to back -->
                    <div class="item-img-block-flag-block">
                      <!-- fake templates avatar remove when connect to back -->
                      <template v-if="item.countryId == countryRussiaId">
                        <img :src="rusCountryPicture" alt="country-flag" />
                      </template>
                      <template v-else>
                        <img :src="foreignCountryPicture" alt="country-flag" />
                      </template>
                      <!-- fake templates avatar remove when connect to back -->
                    </div>
                  </div>
                  <div>
                    <h4 class="account-cochair-text mb-8">{{ item.name }}</h4>
                    <div class="sopred-mpk__list-item-position">
                      {{ item.position }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- Сопредседатели МПК -->

        <!-- Рабочие органы МПК -->
        <div ref="workOrgans" v-if="workOrgansArr && workOrgansArr.length > 0 && isShowSopredMpk">
          <h4 class="account-cochair-text mb-32">Рабочие органы МПК</h4>
          <div class="work-organs-event">
            <div v-for="item in workOrgansArr" :key="item.key" class="work-organs-event__wrapper">
              <div class="work-organs-event__title">
                <h4 class="work-organs-event__sub-title account-cochair-text">
                  {{ item.name }}
                </h4>
                <div class="work-organs-event__date" :class="{ red: item.lastEventZased == null }">
                  {{ resolveWorkOrganDateAndPlace(item) }}
                </div>
              </div>

              <div class="work-organs-event__container">
                <p class="account-cochair-sub-text fs14 mb-12">Председатель с российской стороны:</p>
                <div class="cochairs-events-mpk__list">
                  <template v-if="item.chairman">
                    <div class="cochairs-events-mpk__list-item">
                      <div class="item-img-block">
                        <template v-if="item.picture">
                          <img class="item-img-block-avatar" :src="item.picture" alt="avatar" />
                        </template>
                        <template v-else>
                          <div class="item-img-block-avatar"></div>
                        </template>
                        <div class="item-img-block-flag-block">
                          <img :src="rusCountryPicture" alt="country-flag" />
                        </div>
                      </div>
                      <div class="item-text">
                        <h4 class="account-cochair-text">
                          {{ item.chairman.name }}
                        </h4>
                        <div class="cochairs-events-mpk__position">
                          {{ item.chairman.position }}
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="cochairs-events-mpk__list-item">
                      <div class="item-img-block">
                        <img class="item-img-block-avatar single-flag" :src="rusCountryPicture" alt="country-flag" />
                      </div>
                      <div class="item-text">
                        <p>Требуется назначение председателя с российской стороны</p>
                      </div>
                    </div>
                  </template>
                </div>
              </div>
              <div class="work-organs-event__container">
                <p class="account-cochair-sub-text fs14 mb-12">Председатель с иностранной стороны:</p>
                <ul class="cochairs-events-mpk__list">
                  <template v-if="item.chairmanForeign">
                    <div class="cochairs-events-mpk__list-item">
                      <div class="item-img-block">
                        <template v-if="item.picture">
                          <img class="item-img-block-avatar" :src="item.picture" alt="avatar" />
                        </template>
                        <template v-else>
                          <div class="item-img-block-avatar"></div>
                        </template>
                        <div class="item-img-block-flag-block">
                          <img :src="foreignCountryPicture" alt="country-flag" />
                        </div>
                      </div>
                      <div class="item-text">
                        <h4 class="account-cochair-text">
                          {{ item.chairmanForeign.name }}
                        </h4>
                        <div class="cochairs-events-mpk__position">
                          {{ item.chairmanForeign.position }}
                        </div>
                      </div>
                    </div>
                  </template>
                  <template v-else>
                    <div class="cochairs-events-mpk__list-item">
                      <div class="item-img-block">
                        <img
                          class="item-img-block-avatar single-flag"
                          :src="foreignCountryPicture"
                          alt="country-flag"
                        />
                      </div>
                      <div class="item-text">
                        <p>Требуется назначение председателя с иностранной стороны</p>
                      </div>
                    </div>
                  </template>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <!-- Рабочие органы МПК -->
      </div>

      <!-- Ключевые события -->
      <div class="cochairs-events-case mb-54" ref="keyEvents" v-if="mpk?.events && mpk.events.length > 0">
        <div class="mb-18 d-flex align-items-center justify-content-between">
          <h3 class="account-cochair-text">Ключевые события</h3>
          <CustomLink :to="{ name: 'Events', query: { countryId } }">Все события</CustomLink>
        </div>
        <div v-for="cochairEvent in cochairEvents" :key="cochairEvent.key" class="cochairs-events-case__group">
          <template v-if="cochairEvent.events.length > 0">
            <h4 class="cochairs-events-case__sub-title account-cochair-text">
              {{ cochairEvent.key }}
            </h4>
            <div class="cochairs-events-case__list">
              <div class="cochairs-events-case__list-item" v-for="event in cochairEvent.events" :key="event.key">
                <div class="item-text-block">
                  <p class="item-text-block__date">
                    {{ $momentFormat(event.eventFirstDate, dateFormat.DD_MMMM_YYYY) }}
                  </p>
                  <div class="item-text-block__content">
                    <p class="item-text-block__type account-cochair-sub-text">
                      {{ event.name }}
                    </p>
                    <p class="item-text-block__place account-cochair-sub-text">
                      {{ event.place }}
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <!-- <div class="cochairs-events-case__container">
            </div> -->
          </template>
        </div>
      </div>
      <!-- Ключевые события -->

      <!-- Сквозные задачи -->
      <div v-if="throughTasks != null && throughTasks.tasksByThematicBlocks.length > 0" class="through-tasks">
        <div class="through-tasks__header" ref="throughTasks">
          <h3 class="through-tasks__title account-cochair-text">Сквозные задачи</h3>
          <div class="through-tasks__stat">
            <h4 class="through-tasks__sub-title account-cochair-sub-text">Всего задач {{ throughTasks.totalTasks }}</h4>
            <h4 class="through-tasks__sub-title account-cochair-sub-text">
              Всего подзадач {{ throughTasks.totalSubtasks }}
            </h4>
          </div>
          <CustomLink :to="{ name: 'cross-cutting-task', params: { id: countryId } }">Все задачи</CustomLink>
        </div>
        <template v-for="(task, index) in throughTasks.tasksByThematicBlocks" :key="index">
          <h4 class="through-tasks__item-header account-cochair-text">
            {{ task.thematicBlock.name }}
          </h4>
          <div v-for="crossTask in task.crossCuttingTasks" :key="crossTask.id" class="mb-8">
            <Collapse
              :name="crossTask.name"
              :content="crossTask.subtasks"
              :countries="crossTask.countries"
              :achievementLevel="crossTask.achievementLevel"
            />
          </div>
        </template>
      </div>
      <!-- Сквозные задачи -->

      <!-- Мониторинг исполнения поручений МПК + диаграмму добавить -->
      <div v-if="lastEventZased">
        <div class="mb-24 d-flex align-items-center justify-content-between">
          <h3 class="account-cochair-text" ref="monitoringMpk">Мониторинг исполнения поручений МПК</h3>
          <CustomLink :to="{ name: 'AssignmentMonitoring', query: { countryId } }">Все поручения</CustomLink>
        </div>
        <div class="mb-54">
          <!-- diagramma -->
          <div class="orders-monitoring">
            <div v-if="assignmentTotal.totalCount > 0" class="orders-monitoring__diagramma">
              <ChartDonut
                :height="300"
                :width="300"
                template="stock"
                :seriesProp="donut"
                :colors="donut.map(({ color }) => color)"
                :data-labels="{
                  colors: ['#000'],
                  offset: 0,
                  enebledShadow: false,
                }"
              />
            </div>
            <div class="orders-monitoring__diagramma-info">
              <h4 class="account-cochair-text mb-20">
                {{ lastEventZased.name }}, {{ $momentFormat(lastEventZased.eventFirstDate, dateFormat.DD_MMMM_YYYY) }},
                {{ lastEventZased.place }}
              </h4>
              <ul class="orders-monitoring__legend">
                <li>
                  <span class="count fw500">{{ assignmentTotal.totalCount ?? 0 }}</span>
                  {{ orderMonitoringStatus.ALL }}
                </li>
                <li>
                  <span class="circle bg-green"></span><span class="count">{{ assignmentTotal.done ?? 0 }}</span>
                  {{ orderMonitoringStatus.COMPLETED }}
                </li>
                <li>
                  <span class="circle bg-yellow"></span><span class="count">{{ assignmentTotal.inWork ?? 0 }}</span>
                  {{ orderMonitoringStatus.WORK }}
                </li>
                <li>
                  <span class="circle bg-red"></span>
                  <span class="count"> {{ assignmentTotal.overdue ?? 0 }}</span>
                  {{ orderMonitoringStatus.OVERDUE }}
                </li>
                <li>
                  <span class="circle bg-gray"></span>
                  <span class="count">{{ assignmentTotal.notExecute ?? 0 }}</span>
                  {{ orderMonitoringStatus.NOT_COMPLETED }}
                </li>
              </ul>
            </div>
          </div>
          <div class="orders">
            <div class="orders__selects">
              <div class="orders__two-selects">
                <div class="chairs-office-select w-200">
                  <Multiselect
                    v-model="executor"
                    valueProp="id"
                    track-by="text"
                    label="text"
                    placeholder="Все исполнители"
                    :options="executors"
                  >
                  </Multiselect>
                </div>

                <!-- select -->
                <div class="chairs-office-select w-170 category-select">
                  <Multiselect
                    v-model="category"
                    valueProp="id"
                    track-by="text"
                    label="text"
                    placeholder="Все категории"
                    :options="categories"
                  >
                    <template v-slot:option="{ option }">
                      <div :class="`category-status-${option.id}`">
                        {{ option.text }}
                      </div>
                    </template>
                  </Multiselect>
                </div>
              </div>

              <div
                class="hide-btn-with-block-arrow"
                :class="{ active: checkHotIndicatorTable }"
                @click="checkHotIndicatorTable = !checkHotIndicatorTable"
              >
                <p>
                  {{ checkHotIndicatorTable ? 'Cвернуть' : 'Развернуть' }}
                </p>
                <span></span>
              </div>
            </div>
            <PerfectScrollbar>
              <div v-if="checkHotIndicatorTable">
                <div v-for="item in hotIndicatorList" :key="item.key" class="orders__container">
                  <h4 class="account-cochair-text mb-18">{{ item.name }} - {{ item.tasks.length }}</h4>
                  <ui>
                    <li class="orders__item" v-for="task in item.tasks" :key="task.key">
                      <div class="orders__status-indicator">
                        <StatusBadge :name="task.lkStatusName" :type="resolveAssignmentStatusClassColor(task)" />
                      </div>
                      <p class="orders__description">
                        {{ task.assignmentBrief }}
                      </p>
                    </li>
                  </ui>
                </div>
              </div>
            </PerfectScrollbar>
          </div>
        </div>
      </div>
      <!-- -->

      <!-- Проекты -->
      <div class="projects mb-72" ref="projects" v-if="projectsArr?.length > 0">
        <div class="mb-32 d-flex align-items-center justify-content-between">
          <h3 class="account-cochair-text">Проекты</h3>
          <CustomLink :to="{ name: 'Projects', query: { countryId } }">Все проекты</CustomLink>
        </div>

        <div v-for="item in projectsArr" :key="item.key" class="projects__item">
          <Collapse>
            <template #header>
              <div class="projects__header">
                <p class="projects__header-name">
                  {{ item.name }}
                </p>
                <p class="projects__header-participant">
                  {{ item.participantsDomesticStr }}
                </p>
                <div class="projects__header-status account-cochair-sub-text">
                  <StatusBadge :name="item.status.name" :type="getProjectColor(item.statusId)" />
                </div>
              </div>
            </template>
            <template v-if="item.discussionEvents.length == 0" #icon>
              <span></span>
            </template>
            <template #body>
              <div v-if="item.discussionEvents.length > 0">
                <div v-for="event in item.discussionEvents" :key="event.id" class="d-flex projects__body">
                  <p class="projects__body-date account-cochair-text">
                    {{ resolveEventProjectDate(event.eventDate) }}
                  </p>
                  <p class="projects__body-name">
                    {{ event.name }}
                  </p>
                </div>
              </div>
              <div v-else></div>
            </template>
          </Collapse>
        </div>
      </div>
      <!-- Проекты -->
    </div>
  </section>
</template>

<script>
  import { PerfectScrollbar } from 'vue3-perfect-scrollbar';
  import { mapActions, mapGetters } from 'vuex';
  import Multiselect from '@vueform/multiselect';
  import { orderBy } from 'lodash';
  import moment from 'moment';

  import Collapse from '@/common/components/Collapse.vue';
  import CustomLink from '@/common/components/CustomLink.vue';
  import RedesignedSwitchComponent from '@/common/components/redesigned/RedesignedSwitchComponent.vue';
  import StatusBadge from '@/common/components/redesigned/StatusBadge.vue';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import text from '@/common/mixins/text';

  import ChartDonut from '@/components/ChartComponents/ChartDonut.vue';

  import API from '../api/accountCochair';
  import API_mpk from '../api/commission';
  import AccountCochairIndicator from '../components/AccountCochairIndicator.vue';
  import { DiffPlace, OrderMonitoringStatus, OrderMonitoringStatusId } from '../utils/constants';

  import { DIRECTIVES_ACTIONS_NAME, DIRECTIVES_GETTERS_NAME } from '@/store/directives';

  export default {
    name: 'AccountCochair',
    components: {
      ChartDonut,
      Collapse,
      Multiselect,
      PerfectScrollbar,
      RedesignedSwitchComponent,
      AccountCochairIndicator,
      CustomLink,
      StatusBadge,
    },
    mixins: [security, text],
    data() {
      return {
        countryRussiaId: Constants.countryRussiaId,
        dateFormat: Constants.DATE_FORMATS,
        orderMonitoringStatus: OrderMonitoringStatus,
        orderMonitoringStatusId: OrderMonitoringStatusId,
        indicatorValue: Constants.indicator.value,
        indicatorPreviousPercent: Constants.indicator.previousPercentValue,
        indicatorPlaceInMarket: Constants.indicator.placeInMarket,
        diffPlace: DiffPlace,
        mpkCommissionByCountry: [],

        rusCountryPicture: null,
        foreignCountryPicture: null,
        category: null,
        executor: null,
        countryId: this.$route.params.id ?? 0,
        sopredArr: [],
        projectsArr: [],
        hotIndicators: [],
        assignmentTotal: {},
        workOrgansArr: [],
        events: null,
        lastEventZased: null,
        throughTasks: null,
        mpkRatings: null,
        countryName: null,
        yearList: [],
        currentYear: 0,
        monitoringLkStatuses: [],
        efficiencyRatingPlace: null,
        isShowSopredMpk: false,
        checkHotIndicatorTable: false,
        PARTICIPANT_CATEGORY_ID: 1, // id председателя МПК

        isProductMode: true,
        currentSummary: null,
        currentSummaryLoading: false,
        divider: 1000000,
      };
    },
    async created() {
      this.checkPermissionAndRedirect(Constants.Permissions.AccountCochairAccess);

      API.findRussia().then((response) => {
        this.rusCountryPicture = response.data.picture;
      });
      await this.fetchCountries();
      if (this.countryId > 0 && !this.getCountryList?.some((x) => x.id == this.countryId)) {
        this.countryId = 0;
      }
      if (this.countryId <= 0) this.countryId = this.getCountryList[0]?.id;

      this.loadByCountry();
    },
    computed: {
      ...mapGetters({
        getCountryList: DIRECTIVES_GETTERS_NAME.getNsiCountryList,
      }),
      efficiencyRating() {
        const mpkRatings = Object.keys(this.mpkRatings).map((key) => key);

        const all = mpkRatings.length;
        return `${this.efficiencyRatingPlace}/${all}`;
      },
      hotIndicatorList() {
        const filteredExecutors =
          this.executor == 0 || this.executor == null
            ? null
            : this.executors.find(({ id }) => id === Number(this.executor));

        const filteredByExecutor =
          filteredExecutors == null
            ? this.hotIndicators
            : this.hotIndicators.filter(({ name }) => name === filteredExecutors.text);

        return this.category == 0 || this.category == null
          ? filteredByExecutor
          : this.getFilteredExecutors(filteredByExecutor);
      },
      executors() {
        const executors = this.hotIndicators.map(({ name }, index) => ({
          id: index + 1,
          text: name,
          selected: false,
        }));
        return [{ id: 0, text: 'Все исполнители', selected: false }, ...executors];
      },
      categories() {
        return [{ id: 0, text: 'Все', selected: false }, ...this.monitoringLkStatuses];
      },
      menuAnchorsArr() {
        return [
          { title: 'Ключевые результаты', anchor: 'keyResults', visible: true },
          {
            title: 'Рейтинг эффективности МПК',
            anchor: 'efficiency',
            visible: this.efficiencyRatingPlace != null,
          },
          {
            title: 'Структура МПК',
            anchor: 'structureMpk',
            visible: this.sopredArr?.length > 0 || (this.workOrgansArr && this.workOrgansArr.length > 0),
          },
          {
            title: 'Ключевые события',
            anchor: 'keyEvents',
            visible: this.mpk?.events && this.mpk.events.length > 0,
          },
          {
            title: 'Сквозные задачи',
            anchor: 'throughTasks',
            visible: this.throughTasks != null && this.throughTasks.tasksByThematicBlocks.length > 0,
          },
          {
            title: 'Мониторинг исполнения поручений МПК',
            anchor: 'monitoringMpk',
            visible: this.lastEventZased != null,
          },

          {
            title: 'Проекты',
            anchor: 'projects',
            visible: this.projectsArr?.length > 0,
          },
        ];
      },
      cochairEvents() {
        return [
          { key: 'Планируемые', events: this.events.eventsFuture },
          { key: 'Прошедшие', events: this.events.eventsPast },
        ];
      },
    },
    methods: {
      ...mapActions({
        fetchCountries: DIRECTIVES_ACTIONS_NAME.getNsiCountry,
      }),
      fetchHotIndicator() {
        this.currentSummaryLoading = true;
        API.getForeignTradeForOne(this.isProductMode, this.currentYear, this.countryId).then((response) => {
          this.currentSummary = response.data?.summary;
          this.currentSummaryLoading = false;
        });
      },
      fetchProjects() {
        API.getProjects(this.countryId).then((response) => {
          this.projectsArr = response.data?.items;
        });
      },
      fetchAssignments(id) {
        API.getAssigments({
          mpkEventId: id,
          isStart: true,
          SortColumn: 8,
          SortOrder: 1,
        }).then((response) => {
          response.data?.items.forEach((el) => {
            let groupEl = this.hotIndicators.find((x) => x.name == el.organizationName);
            if (groupEl == null) {
              groupEl = { name: el.organizationName, tasks: [] };
              this.hotIndicators.push(groupEl);
            }
            groupEl.tasks.push(el);
          });
        });
      },
      fetchAssignmentsByFoiv({ foiv, prevFoiv = null }) {
        API.getAssigmentsByFoiv({
          mpkEventId: foiv.id,
        }).then((response) => {
          this.lastEventZased = foiv;
          this.assignmentTotal = {};
          if (response.data && response.data.length > 0 && response.data[0].totalCount != 0) {
            this.assignmentTotal = response.data[0];
            const donut = [
              {
                name: this.orderMonitoringStatus.COMPLETED,
                count: this.assignmentTotal.done ?? 0,
                color: '#25c05a',
              },
              {
                name: this.orderMonitoringStatus.IN_PROCESS,
                count: this.assignmentTotal.inWork ?? 0,
                color: '#FFD966',
              },
              {
                name: this.orderMonitoringStatus.OVERDUE,
                count: this.assignmentTotal.overdue ?? 0,
                color: '#FF6666',
              },
              {
                name: this.orderMonitoringStatus.NOT_COMPLETED,
                count: this.assignmentTotal.notExecute ?? 0,
                color: '#787F8F',
              },
            ];
            // чтобы на графике не отображался 0
            this.donut = donut.filter(({ count }) => count !== 0);
            //загрузить по ним информацию
            this.fetchAssignments(this.lastEventZased.id);
            return;
          }
          if (prevFoiv?.id != null) {
            this.fetchAssignmentsByFoiv({ foiv: prevFoiv });
          }
        });
      },
      fetchCrosscuttingTasks() {
        API.getCrossCuttingTasks(this.countryId).then((response) => {
          this.throughTasks = response.data ?? null;
        });
      },
      fetchMpkRatingForCountries() {
        API.getMpkRatingForCountries().then((response) => {
          this.mpkRatings = response?.data?.ratings;
        });
      },
      fetchYearList() {
        API.getYearList(true, true).then((response) => {
          this.yearList = response.data;
          this.currentYear = this.yearList.slice(-1)[0].id;
        });
      },
      fetchMpkMonitoringLkStatuses() {
        API.getMpkMonitoringLkStatuses().then((response) => {
          this.monitoringLkStatuses = response.data ?? [];
        });
      },
      fetchMpkRatingForCountryById() {
        this.efficiencyRatingPlace = null;
        API.getMpkRatingForCountryById(this.countryId).then((response) => {
          this.efficiencyRatingPlace = response?.data.summaryRatingPosition;
        });
      },
      loadByCountry() {
        this.fetchYearList();
        this.fetchHotIndicator();
        this.fetchMpkRatingForCountries();
        this.fetchCrosscuttingTasks();
        this.fetchProjects();
        this.fetchMpkMonitoringLkStatuses();
        this.fetchMpkRatingForCountryById();

        API_mpk.getListByCountry(this.countryId).then((response) => {
          this.mpkCommissionByCountry = response.data;
        });

        API.findByCountry(this.countryId).then((response) => {
          this.mpk = response.data;

          this.sopredArr = [];
          this.workOrgansArr = [];
          this.events = null;
          this.lastEventZased = null;
          this.hotIndicators = [];
          this.countryName = null;

          if (this.mpk != null) {
            this.foreignCountryPicture = this.mpk.country.picture;
            this.countryName = this.mpk.country?.name;

            const sopredRuss = this.mpk.mpkParticipants.find(
              (x) =>
                x.participant.participantCategoryId == this.PARTICIPANT_CATEGORY_ID &&
                x.participant.countryId == this.countryRussiaId,
            );
            if (sopredRuss) this.sopredArr.push(sopredRuss.participant);

            const sopredForeign = this.mpk.mpkParticipants.find(
              (x) =>
                x.participant.participantCategoryId == this.PARTICIPANT_CATEGORY_ID &&
                x.participant.countryId != this.countryRussiaId,
            );
            if (sopredForeign) this.sopredArr.push(sopredForeign.participant);

            //рабочие группы
            const params = {
              pageNumber: 1,
              pageSize: -1,
              hasParent: true,
              parentId: this.mpk.id,
            };
            API_mpk.search(params).then((response) => {
              const organs = response.data?.items;
              this.workOrgansArr = this.sortedWorkOrgans(organs);
            });
            // события
            if (this.mpk.events.length == 0) {
              return;
            }
            this.events = this.processEvents(this.mpk.events);
            const filteredEventZased = this.mpk.events.filter((x) => x.eventTypeId == 1);
            const lastEvents = orderBy(filteredEventZased, (ev) => ev.eventLastDate, 'asc');
            if (lastEvents.length > 0) {
              const lastEventZased = lastEvents[lastEvents.length - 1];
              const prevEventZased = lastEvents.length > 1 ? lastEvents[lastEvents.length - 2] : null;
              this.fetchAssignmentsByFoiv({
                foiv: lastEventZased,
                prevFoiv: prevEventZased,
              });
            }
          }
        });
      },
      processEvents(arr) {
        const byTypeArr = [];
        if (arr && arr.length > 0) {
          arr.forEach((el) => {
            if (byTypeArr[el.eventTypeId] === undefined) {
              byTypeArr[el.eventTypeId] = {
                id: el.eventTypeId,
                name: el.eventTypeName,
                eventsFuture: [],
                eventsPast: [],
                eventPast: null,
                eventFuture: null,
              };
            }

            const date = this.$momentFormat(el.eventLastDate, this.dateFormat.YYYY_MM_DD_SLASH);
            const now = moment().format(this.dateFormat.YYYY_MM_DD_SLASH);

            //byTypeArr[el.eventTypeId].events.push(el);
            const isPast = date < now;
            if (isPast) byTypeArr[el.eventTypeId].eventsPast.push(Object.assign({}, el));
            else if (el.statusId <= 2 || date === now)
              byTypeArr[el.eventTypeId].eventsFuture.push(Object.assign({}, el));
          });
        }

        byTypeArr.forEach(function (el) {
          if (el.eventsFuture && el.eventsFuture.length > 0) {
            el.eventsFuture.forEach((event) => {
              if (
                el.eventFuture == null ||
                (el.eventFuture.eventLastDate == null && event.eventLastDate != null) ||
                (el.eventFuture.eventLastDate != null &&
                  event.eventLastDate != null &&
                  moment(el.eventFuture.eventLastDate) > moment(event.eventLastDate))
              ) {
                el.eventFuture = Object.assign({}, event);
              }
            });
          }

          if (el.eventsPast && el.eventsPast.length > 0) {
            el.eventsPast.forEach((event) => {
              if (el.eventPast == null || moment(el.eventPast.eventLastDate) < moment(event.eventLastDate)) {
                el.eventPast = Object.assign({}, event);
              }
            });
          }
        });

        return {
          eventsFuture: byTypeArr.filter((x) => x.eventFuture != null).map((x) => x.eventFuture),
          eventsPast: byTypeArr.filter((x) => x.eventPast != null).map((x) => x.eventPast),
        };
      },
      scrollTo(refName) {
        const element = this.$refs[refName];
        const top = element.offsetTop;
        window.scrollTo(0, top);
      },
      onCountryFilter() {
        this.$router.push({ name: 'AccountCochair', params: { id: this.countryId } });
        this.loadByCountry();
      },
      switchHand(e) {
        this.isProductMode = e;
        this.divider = e ? 1000000 : 1000;
        this.fetchHotIndicator();
      },
      getProjectColor(statusId) {
        if (statusId == 1) return 'blue';
        if (statusId == 2 || statusId == 6) return 'green';
        if (statusId == 3) return 'yellow';
        return 'red';
      },
      resolveAssignmentStatusClassColor(el) {
        switch (Number(el.lkStatusId)) {
          case this.orderMonitoringStatusId.IN_WORK: {
            return 'yellow';
          }
          case this.orderMonitoringStatusId.DONE: {
            return 'green';
          }
          case this.orderMonitoringStatusId.NOT_DONE: {
            return 'grey';
          }
          case this.orderMonitoringStatusId.OVERDUE: {
            return 'red';
          }
          default: {
            return 'red';
          }
        }
      },
      resolveEventProjectDate(date) {
        const currentDate = new Date(date);
        const month = currentDate.getMonth();
        return month != null
          ? this.$momentFormat(date, this.dateFormat['MMM, YYYY'])
          : this.$momentFormat(date, this.dateFormat.YYYY);
      },
      resolveWorkOrganDateAndPlace(item) {
        return item.lastEventZased
          ? (item.lastEventZased.place ?? '') +
              ' - ' +
              this.$momentFormat(item.lastEventZased.eventFirstDate, this.dateFormat['DD.MM.YYYY'])
          : 'Заседания не проводились';
      },
      getFilteredExecutors(executors) {
        return executors.map(({ name, tasks }) => {
          const filteredTasks = tasks.filter(({ lkStatusId }) => lkStatusId === Number(this.category));
          const orderTasks = orderBy(filteredTasks, (task) => task.lkStatusId, 'desc');
          return {
            name,
            tasks: orderTasks,
          };
        });
      },
      sortedWorkOrgans(organs) {
        return organs.sort((a, b) => {
          const oneDate = new Date(a?.lastEventZased?.eventFirstDate ?? 0);
          const twoDate = new Date(b?.lastEventZased?.eventFirstDate ?? 0);
          return oneDate.getTime() - twoDate.getTime();
        });
      },
    },
    mounted() {},
  };
</script>

<style lang="scss" scoped>
  @import '../styles/AccountCochair.scss';
</style>

<style lang="scss">
  .account-custom-select,
  .chairs-office-select {
    .multiselect-single-label {
      padding-right: 30px;
    }
    .multiselect-dropdown {
      border: 1px solid $light-grey-5;
      box-shadow: 0px 0px 16px 0px #0000000d;
      border-radius: 4px;
    }
    .multiselect-option.is-selected {
      background: $dark-grey-1;
      pointer-events: none;
    }
    .multiselect-option.is-pointed:hover {
      background: $light-grey-5 !important;
    }
    .multiselect-clear-icon {
      display: none;
    }
    .multiselect-option.is-selected.is-pointed {
      background: $dark-grey-1;
    }
  }
  .account-custom-select {
    .multiselect {
      border: none;
      background: none;
      .multiselect-search {
        background: none;
      }
      .multiselect-caret {
        border-left: 3px solid $blue;
        border-bottom: 3px solid $blue;
      }
    }
    .multiselect.is-active {
      border: none;
    }
    .multiselect-checkbox,
    .multiselect-single-label-text {
      font: $fira-24;
      color: $blue;
    }
    input:focus,
    textarea:focus {
      border: none;
    }
  }

  .chairs-office-select {
    .multiselect {
      border: none;
      background: none;
    }
    .multiselect-checkbox,
    .multiselect-single-label-text,
    .multiselect-placeholder {
      font: $fira-16;
      color: $gray-silver-dark;
    }
    .multiselect-caret {
      width: 7px !important;
      height: 7px !important;
      border-left: 2px solid $gray-silver-dark;
      border-bottom: 2px solid $gray-silver-dark;
    }

    .multiselect-dropdown > ul {
      li {
        div {
          width: 100%;
          padding: 5px;
          text-align: center;
          border-radius: 5px;
        }
      }
    }
  }
  .category-select {
    .multiselect-dropdown {
      overflow-y: unset;
      max-height: 15rem;
    }
  }
  .ps {
    max-height: calc(100vh - 100px);
    max-width: 100%;
  }

  .projects__header-status {
    .status-badge {
      min-width: unset;
      max-width: unset;
    }
  }
</style>
