<template>
  <div class="indicator">
    <div class="account-cochair-sub-text w-100 text-align-left fs14 fw400">
      {{ name }}, <span class="ml-4"> {{ dividerLabel }} $</span>
    </div>
    <div class="d-flex align-items-center">
      <strong class="indicator__item-value">
        {{ getResolveIndicatorValue }}
      </strong>
    </div>
    <div class="indicator__params-wrapper">
      <div class="d-flex fs14" v-if="getResolveShowIndicatorPercent">
        <i class="fas fa-solid indicator__arrow" :class="resolveIconClass(previousPercent)"></i>
        <span class="account-cochair-sub-text" :class="getResolveIndicatorPercentClass">
          {{ getPreparedPreviousPercent }}%
        </span>
      </div>
      <div class="d-flex fs14" v-if="currentSummary?.[indicatorPlace]">
        <i class="fas fa-solid indicator__arrow" :class="resolveIconClass(diffPlace)"></i>
        <span class="account-cochair-sub-text"> {{ Math.abs(currentSummary?.[indicatorPlace]) }} место </span>
      </div>
    </div>
  </div>
</template>

<script>
  import text from '@/common/mixins/text';

  import { DIVIDER_LABEL } from '../utils/constants';

  export default {
    name: 'AccountCochairIndicator',
    mixins: [text],
    props: {
      name: {
        type: String,
        default: '',
      },
      currentSummary: {
        type: Object,
        default: () => {},
      },
      indicatorValue: {
        type: String,
        default: '',
      },
      previousPercent: {
        type: String,
        default: '',
      },
      divider: {
        type: Number,
        default: 1000000,
      },
      diffPlace: {
        type: String,
        default: '',
      },
      indicatorPlace: {
        type: String,
        default: '',
      },
    },
    data() {
      return {
        dividerLabel: DIVIDER_LABEL,
      };
    },
    computed: {
      getResolveIndicatorValue() {
        return this.formatNumber(this.currentSummary[this.indicatorValue] / this.divider) == '0,00'
          ? '-'
          : this.formatNumber(this.currentSummary[this.indicatorValue] / this.divider, 1);
      },
      getPreparedPreviousPercent() {
        return this.formatNumber(this.currentSummary?.[this.previousPercent], 1);
      },
      getResolveShowIndicatorPercent() {
        return (
          this.currentSummary?.['balancePreviousPercent'] != null &&
          this.formatNumber(this.currentSummary[this.indicatorValue] / this.divider) != '0,00'
        );
      },
      getResolveIndicatorPercentClass() {
        return [
          {
            'text-red': this.currentSummary[this.previousPercent] < 0,
          },
          {
            'text-green': this.currentSummary[this.previousPercent] > 0,
          },
        ];
      },
    },
    methods: {
      resolveIconClass(mode) {
        return [
          {
            'fa-arrow-down text-red': this.currentSummary?.[mode] < 0,
          },
          {
            'fa-arrow-up text-green': this.currentSummary?.[mode] > 0,
          },
        ];
      },
    },
  };
</script>

<style lang="scss" scoped>
  @import '../styles/AccountCochair.scss';
</style>

<style lang="scss" scoped>
  .indicator {
    min-width: 180px;
    min-height: 110px;
    box-shadow: 0px 0px 16px 0px #37373714;
    padding: 15px;

    &__item-value {
      font: $fira-32-B;
      margin-right: 3px;
    }

    &__arrow {
      display: flex;
      align-items: center;
      margin-right: 5px;
    }

    &__params-wrapper {
      display: flex;
      justify-content: space-between;
    }
  }
</style>
