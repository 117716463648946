import axios from 'axios';

import { authAxios } from '@/common/axios';
import Constants from '@/common/constants';

export default {
  countryList: function () {
    return axios.get('/api/mpkCommission/countryList');
  },
  findByCountry: function (id) {
    return axios.get(`/api/mpkCommission/findByCountry?id=${id}`);
  },
  findRussia: function () {
    var id = Constants.countryRussiaId;
    return authAxios.get(`/api/country/${id}`);
  },
  getForeignTradeForOne: function (isProductMode, year, countryId) {
    return axios.get(
      `api/foreigntrade/summaryForOne/${year}/${countryId}?isRussia=true&isProductMode=${isProductMode}`,
      {},
    );
  },
  getProjects: function (countryId) {
    return axios.get('/api/mpkProject/search', {
      params: { countryIds: [countryId] },
    });
  },
  getAssigments: function (request) {
    return axios.get('/api/mpkAssignment/search', { params: request });
  },
  getAssigmentsByFoiv: function (request) {
    return axios.get('/api/mpkAssignment/getTotalByCountry', {
      params: request,
    });
  },
  getCrossCuttingTasks: function (id) {
    return axios.get('/api/crosscuttingtask/tasksforcochairmanmpk', {
      params: { countryId: id },
    });
  },
  getCrosscuttingTaskThematicBlocks: function () {
    return axios.get('/api/crosscuttingtask/thematicblocks');
  },
  getMpkRatingForCountries: function () {
    return axios.get('/api/mpkrating/calculateratingsforallcountries');
  },
  getMpkRatingForCountryById: function (id) {
    return axios.get('/api/mpkrating/calculateratingsforcountry', {
      params: { countryId: id },
    });
  },
  getYearList: function (isRussia, isProductMode) {
    return axios.get(`api/foreigntrade/yearList`, {
      params: { isRussia: isRussia, isProductMode: isProductMode },
    });
  },
  getMpkMonitoringLkStatuses: function () {
    return axios.get('api/mpkassignment/getmpkmonitoringlkassignmentstatuslist');
  },
};
